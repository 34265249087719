import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL || 'https://apiv2.cheerclip.com';

// Create an Axios instance
const instance = axios.create({
  baseURL,
});

export default instance;

import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Form, Button, Row, Col, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaEdit, FaCheck, FaTimes } from 'react-icons/fa';
import Avatar from 'react-avatar-edit';
import QRCode from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './UserSettings.css';

// Import service functions
import {
    fetchUserInformation,
    fetchUserOptions,
    fetchUserSettings,
    fetchUserDetails,
    checkNicknameAvailability,
    updateUserDetails,
    updateUserOptions,
    updateProfilePicture,
} from '../../services/apiService';

const UserSettings = () => {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    // State management for user options and profile
    const [user_options, set_user_options] = useState({
        email: '',
        email_verified: false,
        nickname: '',
        old_nickname: '',
        profile_picture: '',
        usage: 0,
        usage_limit: 100,
        profile_visibility: 'public',
        receive_newsletter: false,
        show_disclaimer: true,
        public_id: '',
        account_type: 'free',
    });

    const [show_avatar_editor, set_show_avatar_editor] = useState(false);
    const [preview, set_preview] = useState(null);
    const [nickname_status, set_nickname_status] = useState(null);

    // Fetch user information on component mount
    useEffect(() => {
        const fetch_user_info = async () => {
            try {
                if (isAuthenticated) {
                    const token = await getAccessTokenSilently();
                    const fetched_user_options = {};

                    // Fetch usage details
                    try {
                        const db_info = await fetchUserInformation(token);
                        if (db_info) {
                            fetched_user_options.usage = db_info.used_storage_space ?? 0;
                        }
                    } catch (error) {
                        console.error('Failed to fetch user information:', error);
                        toast.error('Failed to fetch user information.');
                    }

                    // Fetch user options
                    try {
                        const options = await fetchUserOptions(token);
                        if (options) {
                            fetched_user_options.profile_visibility = options.profile_visibility ?? 'private';
                            fetched_user_options.receive_newsletter = options.receive_newsletter ?? false;
                            fetched_user_options.show_disclaimer = options.show_disclaimer ?? true;
                        }
                    } catch (error) {
                        console.error('Failed to fetch user options:', error);
                        toast.error('Failed to fetch user options.');
                    }

                    // Fetch user settings
                    try {
                        const settings = await fetchUserSettings(token);
                        if (settings) {
                            fetched_user_options.usage_limit = settings.usage_limit ?? 5;
                            fetched_user_options.account_type = settings.account_type ?? "free";
                        }
                    } catch (error) {
                        console.error('Failed to fetch user settings:', error);
                        toast.error('Failed to fetch user settings.');
                    }

                    // Fetch user details
                    try {
                        const details = await fetchUserDetails(token);
                        if (details) {
                            fetched_user_options.nickname = details.nickname ?? '';
                            fetched_user_options.old_nickname = details.nickname ?? '';
                            fetched_user_options.email = details.email ?? '';
                            fetched_user_options.profile_picture = details.profile_picture ?? '';
                            fetched_user_options.public_id = details.public_id ?? '';
                        }
                    } catch (error) {
                        console.error('Failed to fetch user details:', error);
                        toast.error('Failed to fetch user details.');
                    }

                    set_user_options((prev_state) => ({
                        ...prev_state,
                        ...fetched_user_options,
                    }));
                }
            } catch (error) {
                console.error('Failed to fetch user information:', error);
                toast.error('Failed to fetch user information.');
            }
        };

        fetch_user_info();
    }, [getAccessTokenSilently, isAuthenticated]);

    // Check nickname availability
    const check_nickname_availability_handler = async (nickname) => {
        try {
            const token = await getAccessTokenSilently();
            const response = await checkNicknameAvailability(token, nickname);
            set_nickname_status(response && response.exists ? 'unavailable' : 'available');
        } catch (error) {
            console.error('Error checking nickname availability:', error);
            set_nickname_status('error');
        }
    };

    // Handle input changes
    const handle_input_change = (e) => {
        const { name, value, type, checked } = e.target;
        const field_value = type === 'checkbox' ? checked : value;

        if (name === 'nickname' && value !== user_options.nickname) {
            if (value === user_options.old_nickname) {
                set_nickname_status(null);
            } else {
                check_nickname_availability_handler(value);
            }
        }

        set_user_options((prev_state) => ({ ...prev_state, [name]: field_value }));
    };

    // Save changes
    const handle_save_changes = async () => {
        if (nickname_status === 'unavailable') {
            toast.error('Nickname is already taken. Please choose another one.');
            return;
        }

        try {
            const token = await getAccessTokenSilently();
            await updateUserDetails(token, user_options);
            await updateUserOptions(token, user_options);
            toast.success('User information updated successfully.');
        } catch (error) {
            console.error('Failed to update user information:', error);
            toast.error('Failed to update user information.');
        }
    };

    // Profile picture handlers
    const on_close = () => set_preview(null);

    const on_crop = (preview) => set_preview(preview);

    const on_before_file_load = (elem) => {
        if (elem.target.files[0].size > 11468800) {
            alert('File is too big!');
            elem.target.value = '';
        }
    };

    const handle_profile_picture_save = async () => {
        if (preview) {
            try {
                set_show_avatar_editor(false);
                const token = await getAccessTokenSilently();
                await updateProfilePicture(token, preview);
                toast.success('Profile picture updated successfully.');
                set_user_options((prev_state) => ({
                    ...prev_state,
                    profile_picture: preview,
                }));
            } catch (error) {
                console.error('Failed to update profile picture:', error);
                toast.error('Failed to update profile picture.');
            }
        }
    };

    return (
        <Container className="user-settings-container">
            <ToastContainer />
            <h2 className="my-4">User Settings</h2>
            <Form>
                <Row className="mb-3">
                    <Col md={4}>
                        <h5>Profile</h5>
                    </Col>
                    <Col md={8}>
                        <OverlayTrigger placement="right" overlay={<Tooltip>Edit Profile Picture</Tooltip>}>
                            <div className="profile-picture-container" onClick={() => set_show_avatar_editor(true)}>
                                <Image
                                    src={user_options.profile_picture || 'https://via.placeholder.com/150'}
                                    roundedCircle
                                    className="profile-picture"
                                />
                                <FaEdit className="edit-icon" />
                            </div>
                        </OverlayTrigger>
                        <Form.Group controlId="formEmail" className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" name="email" value={user_options.email} disabled />
                        </Form.Group>
                        <Form.Group controlId="formNickname" className="mb-3">
                            <Form.Label>Nickname</Form.Label>
                            <Form.Control type="text" name="nickname" value={user_options.nickname} onChange={handle_input_change} />
                            {nickname_status === 'available' && user_options.nickname && <FaCheck style={{ color: 'green', marginLeft: '10px' }} />}
                            {nickname_status === 'unavailable' && user_options.nickname && <FaTimes style={{ color: 'red', marginLeft: '10px' }} />}
                        </Form.Group>
                        <Form.Group controlId="formProfileVisibility" className="mb3">
                            <Form.Label>Profile Visibility</Form.Label>
                            <Form.Control as="select" name="profile_visibility" value={user_options.profile_visibility} onChange={handle_input_change}>
                                <option value="public">Public</option>
                                <option value="private">Private</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4}>
                        <h5>Usage</h5>
                    </Col>
                    <Col md={8}>
                        <Form.Group controlId="formUsage" className="mb-3">
                            <Form.Label>Usage (GB)</Form.Label>
                            <Form.Control type="number" name="usage" value={user_options.usage} disabled />
                        </Form.Group>
                        <Form.Group controlId="formUsageLimit" className="mb-3">
                            <Form.Label>Usage Limit (GB)</Form.Label>
                            <Form.Control type="number" name="usage_limit" value={user_options.usage_limit} disabled />
                        </Form.Group>
                        <Form.Group controlId="formAccountType" className="mb-3">
                            <Form.Label>Account Type</Form.Label>
                            <Form.Control type="text" name="account_type" value={user_options.account_type} disabled />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4}>
                        <h5>Options</h5>
                    </Col>
                    <Col md={8}>
                        <Form.Group controlId="formReceiveNewsletter" className="mb-3">
                            <Form.Check
                                type="checkbox"
                                name="receive_newsletter"
                                label="Receive Newsletter"
                                checked={user_options.receive_newsletter}
                                onChange={handle_input_change}
                            />
                        </Form.Group>
                        <Form.Group controlId="formShowDisclaimer" className="mb-3">
                            <Form.Check
                                type="checkbox"
                                name="show_disclaimer"
                                label="Show Disclaimer"
                                checked={user_options.show_disclaimer}
                                onChange={handle_input_change}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4}>
                        <h5>Public ID</h5>
                    </Col>
                    <Col md={8}>
                        {user_options.public_id && (
                            <Form.Group controlId="formPublicId" className="mb-3">
                                <QRCode value={`https://cheerclip.com/friends/${user_options.public_id}`} />
                            </Form.Group>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 8, offset: 4 }}>
                        <Button variant="primary" onClick={handle_save_changes}>
                            Save Changes
                        </Button>
                    </Col>
                </Row>
            </Form>

            {show_avatar_editor && (
                <div className="avatar-editor">
                    <Avatar
                        width={390}
                        height={295}
                        exportSize={300}
                        onCrop={on_crop}
                        onClose={on_close}
                        onBeforeFileLoad={on_before_file_load}
                        src={preview}
                    />
                    {preview && (
                        <div>
                            <Image src={preview} roundedCircle className="profile-picture-preview" />
                            <Button variant="primary" onClick={handle_profile_picture_save}>
                                Save Profile Picture
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </Container>
    );
};

export default UserSettings;

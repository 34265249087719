// utils/videoUtils.js - Utility functions for VideoList component

// Filter and sort videos based on filter option and search query
export const filterAndSortVideos = (videos, filterOption, searchQuery) => {
    try {
      let filtered;
      const now = new Date();
      switch (filterOption) {
        case 'today':
          const startOfDay = new Date(now.setHours(0, 0, 0, 0));
          filtered = videos.filter((video) => new Date(video.metadata.upload_date) >= startOfDay);
          break;
        case 'last3Days':
          filtered = videos.filter((video) => (now - new Date(video.metadata.upload_date)) / (1000 * 60 * 60 * 24) <= 3);
          break;
        case 'last7Days':
          filtered = videos.filter((video) => (now - new Date(video.metadata.upload_date)) / (1000 * 60 * 60 * 24) <= 7);
          break;
        case 'lastMonth':
          filtered = videos.filter((video) => (now - new Date(video.metadata.upload_date)) / (1000 * 60 * 60 * 24) <= 30);
          break;
        default:
          filtered = videos;
      }
  
      if (searchQuery) {
        filtered = filtered.filter((video) => video.hashtags.includes(searchQuery));
      }
  
      filtered.sort((a, b) => new Date(b.metadata.upload_date) - new Date(a.metadata.upload_date));
      return filtered;
    } catch (error) {
      console.error('Error filtering and sorting videos:', error);
      return [];
    }
  };
  
  // Get the number of columns for grid layout
  export const getGridColumns = (isMobile, gridSize) => {
    if (isMobile) {
      switch (gridSize) {
        case 'small':
          return 0; // Swipe mode
        case 'medium':
          return 3;
        case 'large':
          return 5;
        default:
          return 3;
      }
    } else {
      switch (gridSize) {
        case 'small':
          return 3;
        case 'medium':
          return 5;
        case 'large':
          return 7;
        default:
          return 5;
      }
    }
  };
  
import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './VideoList.css';
import VideoListItem from '../VideoListItem/VideoListItem';
import { FaThLarge, FaTh, FaThList, FaTrash, FaDownload, FaCog, FaShareAlt } from 'react-icons/fa';
import { TbSwipe } from "react-icons/tb";
import { useSwipeable } from 'react-swipeable';

import { filterAndSortVideos, getGridColumns } from '../../utils/videoUtils';

// Import the API service functions
import { downloadVideo, processVideo } from '../../services/apiService';

function VideoList({ title, videos, uploadProgress, onDelete, onEdit, isSwipeMode, setIsSwipeMode }) {
  // State variables
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [gridSize, setGridSize] = useState('medium');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [filterOption, setFilterOption] = useState('all');
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [playingVideoId, setPlayingVideoId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [processingVideos, setProcessingVideos] = useState([]);

  useEffect(() => {
    // Apply filtering and sorting when 'videos', 'filterOption', or 'searchQuery' changes
    setFilteredVideos(filterAndSortVideos(videos, filterOption, searchQuery));
  }, [videos, filterOption, searchQuery]);

  useEffect(() => {
    // Handle window resize event
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Swipe handlers for mobile swipe mode
  const swipeHandlers = useSwipeable({
    onSwipedUp: () => {
      console.log('onSwipedUp');
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % filteredVideos.length);
    },
    onSwipedDown: () => {
      console.log('onSwipedDown');
      setCurrentVideoIndex((prevIndex) => (prevIndex - 1 + filteredVideos.length) % filteredVideos.length);
    },
    delta: 50,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  // Handle video selection for batch actions
  const handleVideoSelect = (videoId, isSelected) => {
    setSelectedVideos((prevSelected) =>
      isSelected ? [...prevSelected, videoId] : prevSelected.filter((id) => id !== videoId)
    );
  };

  // Batch delete selected videos
  const handleBatchDelete = () => {
    if (selectedVideos.length === 0) return;
    try {
      selectedVideos.forEach((videoId) => onDelete(videoId));
      setSelectedVideos([]);
    } catch (error) {
      console.error('Error deleting videos:', error);
    }
  };

  // Batch download selected videos
  const handleBatchDownload = () => {
    if (selectedVideos.length === 0) return;
    try {
      selectedVideos.forEach((videoId) => {
        const video = videos.find((v) => v.id === videoId);
        if (video) {
          downloadVideo(video.url, video.original_name);
        }
      });
      setSelectedVideos([]);
    } catch (error) {
      console.error('Error downloading videos:', error);
    }
  };

  // Batch process selected videos
  const handleBatchProcess = () => {
    if (selectedVideos.length === 0) return;

    setProcessingVideos(selectedVideos); // Signal items to process
    setTimeout(() => setProcessingVideos([]), 1000); // Clear processing state after signaling
  };

  // Handle change in grid size
  const handleGridSizeChange = (size) => {
    setGridSize(size);
    if (size === 'small' && isMobile && !isSwipeMode) {
      setIsSwipeMode(true); // Activate swipe mode for small size on mobile
    }
  };

  // Handle change in filter option
  const handleFilterChange = (option) => {
    setFilterOption(option);
  };

  // Handle change in search query
  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle video play
  const handleVideoPlay = (videoId) => {
    setPlayingVideoId(videoId);
  };

  // Close swipe mode
  const closeSwipeMode = () => {
    setIsSwipeMode(false);
  };

  useEffect(() => {
    // Control page overflow when swipe mode is active
    document.body.style.overflow = isSwipeMode ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isSwipeMode]);

  return (
    <div className="container mt-4">
      {/* Controls for grid size, filter, search, and batch actions */}
      {!isSwipeMode && (
        <div className="controls">
          <div className="grid-size">
            <FaTh
              onClick={() => handleGridSizeChange('large')}
              className={`grid-icon ${gridSize === 'large' ? 'selected' : ''}`}
            />
            <FaThLarge
              onClick={() => handleGridSizeChange('medium')}
              className={`grid-icon ${gridSize === 'medium' ? 'selected' : ''}`}
            />
            {isMobile ? (
              <TbSwipe
                onClick={() => handleGridSizeChange('small')}
                className={`grid-icon ${gridSize === 'small' ? 'selected' : ''}`}
              />
            ) : (
              <FaThList
                onClick={() => handleGridSizeChange('small')}
                className={`grid-icon ${gridSize === 'small' ? 'selected' : ''}`}
              />
            )}
          </div>
          <div className="filter-options">
            <select value={filterOption} onChange={(e) => handleFilterChange(e.target.value)}>
              <option value="all">All</option>
              <option value="today">Today</option>
              <option value="last3Days">Last 3 days</option>
              <option value="last7Days">Last 7 days</option>
              <option value="lastMonth">Last month</option>
            </select>
          </div>
          <div className="search-input">
            <input
              type="text"
              placeholder="Search by hashtag"
              value={searchQuery}
              onChange={handleSearchQueryChange}
            />
          </div>
          <div className="batch-actions">
            {selectedVideos.length > 0 && (
              <>
                <button onClick={handleBatchDelete}>
                  <FaTrash /> Delete
                </button>
                <button onClick={handleBatchDownload}>
                  <FaDownload /> Download
                </button>
                <button onClick={handleBatchProcess}>
                  <FaCog /> Process
                </button>
              </>
            )}
          </div>
        </div>
      )}

      {/* Video list view based on isSwipeMode */}
      {isSwipeMode ? (
        <div {...swipeHandlers} className="swipe-container">
          {filteredVideos.length > 0 ? (
            <VideoListItem
              key={filteredVideos[currentVideoIndex].id}
              video={filteredVideos[currentVideoIndex]}
              isPlaying={true}
              onVideoPlay={handleVideoPlay}
              uploadProgress={uploadProgress}
              isProcessing={processingVideos.includes(filteredVideos.id)} 
              onDelete={onDelete}
              onEdit={onEdit}
              onSelect={handleVideoSelect}
              isSelected={selectedVideos.includes(filteredVideos[currentVideoIndex].id)}
              isSwipeMode={true}
              onCloseSwipeMode={closeSwipeMode}
            />
          ) : (
            <div>No videos found</div>
          )}
        </div>
      ) : (
        <div className={`video-grid ${gridSize}`} style={{ gridTemplateColumns: `repeat(${getGridColumns(isMobile, gridSize)}, 1fr)` }}>
          {filteredVideos.length > 0 ? (
            filteredVideos.map((video) => (
              <VideoListItem
                key={video.id}
                video={video}
                isPlaying={playingVideoId === video.id}
                onVideoPlay={handleVideoPlay}
                uploadProgress={uploadProgress}
                isProcessing={processingVideos.includes(video.id)} 
                onDelete={onDelete}
                onEdit={onEdit}
                onSelect={handleVideoSelect}
                isSelected={selectedVideos.includes(video.id)}
              />
            ))
          ) : (
            <div>No videos found</div>
          )}
        </div>
      )}
    </div>
  );
}

export default VideoList;
